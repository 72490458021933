# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
#
#
$(document).on "turbolinks:load", ->
  if($("#associate_device_form_project_id").length)
    projects = $("#associate_device_form_project_id").html()

    escapeChar = (x) -> "\\" + x

    updateProjects = ->
      account = $('#associate_device_form_account_id :selected').text()
      account_escaped = account.replace(/"/g, escapeChar )
      options = $(projects).filter("optgroup[label=\"" + account_escaped + "\"]").html()
      if (options)
        return $('#associate_device_form_project_id').html(options)
      else
        return $('#associate_device_form_project_id').empty()

    $("#associate_device_form_account_id").on 'change', updateProjects
    updateProjects()
