# typeOf = (obj) ->
#   return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase()

$(document).on "turbolinks:load", ->
  element = $('#location_history_flatpickr')
  if element.length
    url = element.data("url")
    @marker = new google.maps.Marker {
      map: @location_history_map,
      position: new google.maps.LatLng(0,0),
      visible: false
    }
    @infowindow = new google.maps.InfoWindow()
    @marker.addListener 'click', =>
      @infowindow.open(@location_history_map, @marker)
    zoom_level = 4
    @trace = new google.maps.Polyline {
      map: @location_history_map,
      geodesic: true,
      strokeColor: '#2c43d6',
      strokeOpacity: 1.0,
      strokeWeight: 2
    }

    @calander = element.flatpickr({
      enableTime: true,
      inline: true,
      time_24hr: true,
      minuteIncrement: 1,
      onReady: (selectedDates, dateStr, instance) ->
        this.setDate(new Date(), true)
      onChange: (selectedDates, dateStr, instance) =>
        date = selectedDates[0]
        params= {'end_time': date, 'start_time' : new Date(new Date().setFullYear(new Date().getFullYear() - 1)), 'limit': '360'  }
        $.getJSON url + '?' + jQuery.param(params), (gps_point) =>
          if gps_point[0]
            time_diff = date - new Date(gps_point[0].time)

            if time_diff < 0 || time_diff > 60000
              @marker.setIcon("https://maps.google.com/mapfiles/ms/icons/red-dot.png")
            else
              @marker.setIcon("https://maps.google.com/mapfiles/ms/icons/green-dot.png")

            @marker.setPosition(new google.maps.LatLng(gps_point[0].latitude, gps_point[0].longitude))
            @marker.setVisible(true)
            @location_history_map.setCenter(@marker.getPosition())
            @location_history_map.setZoom(14)
            @infowindow.setContent(
              "<table>
                <tr><td>latitude:</td><td>#{gps_point[0].latitude}</td></tr>
                <tr><td>longitude:</td><td>#{gps_point[0].longitude}</td></tr>
                <tr><td>time:</td><td>#{gps_point[0].time}</td></tr>
              </table>")

            @marker.addListener 'click', =>
              @infowindow.open(@location_history_map, @marker)

            @trace.setPath(gps_point.map (g) -> {lat: g.latitude, lng: g.longitude})
            @trace.setVisible(true)

            zoom_level = 6
          else
            @marker.setVisible(false)
            @location_history_map.setZoom(zoom_level)
            @infowindow.close()
            @trace.setVisible(false)


    })
