import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "action"];

  connect() {
    this.update();
  }

  selectAll() {
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      this.checkboxTargets[i].checked = true;
    }

    this.update();
  }

  selectNone() {
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      this.checkboxTargets[i].checked = false;
    }

    this.update();
  }

  update() {
    this.updateActionStatus();
  }

  updateActionStatus() {
    const actionsEnabled = this.hasActiveCheckboxes();

    for (let i = 0; i < this.actionTargets.length; i++) {
      const action = this.actionTargets[i];

      if (actionsEnabled) {
        action.classList.remove("disabled");
      } else {
        action.classList.add("disabled");
      }
    }
  }

  // Appends all of the device_ids that were set by checkboxes onto the
  // href of the current target
  appendPath(event) {
    const url = new URL(event.currentTarget.href);
    const { param } = event.currentTarget.dataset;

    for (let i = 0; i < this.checkboxTargets.length; i++) {
      const checkbox = this.checkboxTargets[i];

      if (checkbox.checked === true) {
        url.search += `&${param}=${checkbox.dataset.checkboxId}`;
      }
    }

    event.currentTarget.href = url.href;
  }

  hasActiveCheckboxes() {
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      const checkbox = this.checkboxTargets[i];

      if (checkbox.checked === true) {
        return true;
      }
    }

    return false;
  }
}
