$(document).on "turbolinks:load", ->
  element = $('div#device-group-map')
  if element.length
    url = element.data("url")
    map = new google.maps.Map(element[0], center: {lat: 47.71, lng: 11.644}, zoom: 4)
    new UpdateScheduler(new DeviceGroupMapUpdater(map, url))

    trafficLayer = new google.maps.TrafficLayer()
    $("input[type=checkbox][name=map_traffic").change ->
      if this.checked
        trafficLayer.setMap(map)
      else
        trafficLayer.setMap(null)

$(document).on "turbolinks:load", ->
  element = $('div#device-map')
  if element.length
    url = element.data("url")
    map = new google.maps.Map(element[0], center: {lat: 47.71, lng: 11.644}, zoom: 4)
    new UpdateScheduler(new DeviceMapUpdater(map, url))

    trafficLayer = new google.maps.TrafficLayer()
    $("input[type=checkbox][name=map_traffic").change ->
      if this.checked
        trafficLayer.setMap(map)
      else
        trafficLayer.setMap(null)

$(document).on "turbolinks:load", ->
  element = $('div#location-history-map')
  if element.length
    @location_history_map = new google.maps.Map(element[0], center: {lat: 47.71, lng: 11.644}, zoom: 4)

class UpdateScheduler
  constructor: (@updatable, options = {}) ->
    {@freq = 10000} = options
    @updatable.setup?()
    @interval = setInterval((=> @updatable.update()), @freq)
    $(document).on "turbolinks:load", =>
      clearInterval(@interval)
      @updatable.cleanup?()

class DeviceGroupMapUpdater
  constructor: (@map, @url) ->
    @deviceMarkerArray = new DeviceMarkerArray(@map)

  setup: ->
    @update()

  update: ->
    $.getJSON @url, (devices) =>
      @deviceMarkerArray.update(devices)

class DeviceMapUpdater
  constructor: (@map, @url) ->
    @deviceMarker = new DeviceMarker(@map)
    $('input[type=checkbox][name=map_autozoom]').change  =>
      @setBounds()

  setup: ->
    @update()

  update: ->
    $.getJSON @url, (device) =>
      if device.last_gps_point?
        @deviceMarker.update(device)
        if $('input[type=checkbox][name=map_autozoom]').prop('checked')
          @setBounds()

  setBounds: ->
    @map.setCenter(@deviceMarker.marker.getPosition())
    @map.setZoom(16)

class DeviceMarkerArray
  constructor: (@map) ->
    @deviceMarkers = []
    $('input[type=checkbox][name=map_autozoom]').change  =>
      @setBounds()

  update: (devices) ->
    for dm in @deviceMarkers
      dm.updateFlag = false

    for device in devices
      if device.last_gps_point
        dm = null
        dm = i for i in @deviceMarkers when i.device.id is device.id
        unless dm?
          dm = new DeviceMarker(@map, true)
          @deviceMarkers.push(dm)
        dm.update(device)
        dm.updateFlag = true

    newArray = []
    for x in @deviceMarkers
      if x.updateFlag is true
        newArray.push x
      else
        x.marker.setMap(null)
    @deviceMarkers = newArray
    @setBounds()

  setBounds: ->
    return unless $('input[type=checkbox][name=map_autozoom]').prop('checked')
    bounds = new google.maps.LatLngBounds
    bounds.extend(x.marker.getPosition()) for x in @deviceMarkers

    if bounds.isEmpty()
      @map.setCenter(new google.maps.LatLng(47.71, 11.644))
      @map.setZoom(4)
    else if google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(0,0), bounds.toSpan()) < 20
      @map.setCenter(bounds.getCenter())
      @map.setZoom(16)
    else
      @map.fitBounds(bounds)


class DeviceMarker
  constructor: (@map, @useInfowindow = false) ->
    @marker = new google.maps.Marker(
      map: @map,
      icon: this.markerSymbol
    )
    if @useInfowindow
      @infowindow = new google.maps.InfoWindow
      @marker.addListener 'click',  =>
        @infowindow.open(@map, @marker)

  update: (@device) ->
    @marker.setPosition(new google.maps.LatLng(@device.last_gps_point.latitude, @device.last_gps_point.longitude))
    seconds_ago = (new Date() - new Date(@device.last_gps_point.time)) / 1000

    icon = @marker.getIcon()
    icon.rotation = @device.last_gps_point.track

    if seconds_ago < 60 * 5
      # Green
      icon.fillColor = "#42DF40"
      @marker.setZIndex(3)
    else if seconds_ago < 60 * 20
      # Amber
      icon.fillColor = "#F6D159"
      @marker.setZIndex(2)
    else
      # Red
      icon.fillColor = "#F36C6C"
      @marker.setZIndex(1)

    if @useInfowindow
      @infowindow.setContent(content_for_device(@device))

  setVisible: (value)->
    @marker.setVisible(value)

  markerSymbol:
    path: "M -0.12,-14.67 C 1.85,-14.67 3.77,-14.28 5.58,-13.52 7.32,-12.80 8.91,-11.72 10.24,-10.40 19.29,-1.43 12.67,14.42 -0.12,14.42 -2.09,14.42 -4.01,14.04 -5.83,13.28 -7.56,12.56 -9.16,11.48 -10.49,10.16 -19.53,1.19 -12.91,-14.67 -0.12,-14.67 -0.12,-14.67 -0.12,-14.67 -0.12,-14.67 Z M 6.70,-3.03 C 6.70,-3.03 5.33,-3.88 3.83,-4.82 1.41,-6.32 -1.65,-6.32 -4.07,-4.82 -5.58,-3.88 -6.94,-3.03 -6.94,-3.03 -6.94,-3.03 -0.12,-8.12 -0.12,-8.12 -0.12,-8.12 6.70,-3.03 6.70,-3.03 Z",
    fillOpacity: 1,
    fillColor: "#52F350",
    scale: 0.8,
    strokeColor: 'black',
    strokeWeight: 2,

content_for_device = (device) ->
  "<h4>#{device.name}</h4>
    <table>
      <tr><td>Timestamp:</td><td>#{new Date(device.last_gps_point.time).toLocaleString()}</td></tr>
      <tr><td>Latitude:</td><td>#{device.last_gps_point.latitude}</td></tr>
      <tr><td>Longitude:</td><td>#{device.last_gps_point.longitude}</td></tr>
      <tr><td>Speed:</td><td>#{device.last_gps_point.speed} km/h</td></tr>
      <tr><td>Track:</td><td>#{device.last_gps_point.track}</td></tr>
      <tr><td><a href=#{device.overview_url}>details</a></td></tr>
    </table>"
