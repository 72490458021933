import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pub", "sub"];

  connect() {
    this.toggleOnValue(this.pubTarget.value);
  }

  update(event) {
    this.toggleOnValue(event.currentTarget.value);
  }

  toggleOnValue(value) {
    for (let i = 0; i < this.subTargets.length; i++) {
      const target = this.subTargets[i];

      if (target.dataset.toggleOnValue === value) {
        target.style.display = "block";
      } else {
        target.style.display = "none";
      }
    }
  }
}
